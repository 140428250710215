import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FILE_STATE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  validate,
} from './constant';
import { Component } from './component';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { FormikValues, useFormik } from 'formik';
import { action as fetch } from './action';
import { PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME } from '../profile-psycho-category-list';
import { ANIMAL_TYPE_ENUM } from '../../data/animal/constant';
import { PROFILE_USER_LIST_ANIMAL_MODULE_NAME } from '../profile-user-animal-list';

export const Container: React.FC<{
  isOpen: boolean;
  onToggle: () => void;
  onSuccess?: Function;
}> = ({ isOpen, onToggle, onSuccess }) => {
  const queryClient = useQueryClient();
  const [diploma, setDiploma] = useState<FILE_STATE_INTER[]>([]);
  const [certificate, setCertificate] = useState<File | null>(null);
  const [photo, setPhoto] = useState<File | null>(null);

  const [addDiplomaCount, setAddDiplomaCount] = useState(1);
  const [addCertificateCount, setAddCertificateCount] = useState(1);

  const initialValues = {
    [FORM_VALUE_ENUM.TYPE]: '',
    [FORM_VALUE_ENUM.NAME]: '',
    [FORM_VALUE_ENUM.PHOTO]: null,
    [FORM_VALUE_ENUM.DESCRIPTION]: '',
    [FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]: false,
    [FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION]: '',
    [FORM_VALUE_ENUM.DOG_BREED]: '',
    [FORM_VALUE_ENUM.DOG_AGE]: '',
    [FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]: false,
    [FORM_VALUE_ENUM.DOG_PULLS_LEASH]: false,
    [FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]: false,
    [FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]: false,
    [FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]: false,
    [FORM_VALUE_ENUM.DOG_FEAR]: '',
    [FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]: false,
    [FORM_VALUE_ENUM.DOG_AGGRESSION_REASON]: '',
    [FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT]: false,
    [FORM_VALUE_ENUM.CAT_BREED]: '',
    [FORM_VALUE_ENUM.CAT_CAN_BITE]: false,
    [FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]: false,
    [FORM_VALUE_ENUM.CAT_AGE]: '',
    [FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT]: false,
  };

  const action = useMutation((formData: any) => fetch(formData), {
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries(PROFILE_USER_LIST_ANIMAL_MODULE_NAME);
    },
  });

  const handleSubmit = async () => {
    const formData = new FormData();

    Object.values(FORM_VALUE_ENUM).forEach((key) => {
      const value = formik.values[key];

      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !(typeof value === 'boolean' && value === false)
      ) {
        formData.append(key, String(value));
      }
    });

    if (photo) {
      formData.append('photo', photo);
    }

    if (certificate) {
      if (formik.values[FORM_VALUE_ENUM.TYPE] === ANIMAL_TYPE_ENUM.DOG) {
        formData.append(FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT, certificate);
      } else {
        formData.append(FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT, certificate);
      }
    }

    action.mutate({ payload: formData });
  };

  // const setDiplomaState = (file: FILE_STATE_INTER) => {
  //   setDiploma((prevDiploma) => [...prevDiploma, file]);
  // };

  // const setCertificateState = (file: FILE_STATE_INTER) => {
  //   setCertificate((prevCertificate) => [...prevCertificate, file]);
  // };

  // const onDeleteDiploma = (id: string) => {
  //   setDiploma((prevDiploma) => prevDiploma.filter((file) => file.id !== id));
  // };

  const onDeletePhoto = () => {
    setPhoto(null);
  };

  const onDeleteCertificate = () => {
    setCertificate(null);
  };

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: () => {},
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  // const isDisabledButton = diploma?.length === 0 || certificate?.length === 0;

  const isSubmitDisabled = () => {
    // if (isDisabledButton) {
    //   return true;
    // }

    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (
    options: { value: number; label: string }[],
    value: any,
  ) => {
    if (typeof formik.values[value] != 'object') {
      const optionList = options?.filter(
        (item: any) => item.value === formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: any) => item.value === formik.values[value]?.value,
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      onDeletePhoto={onDeletePhoto}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled()}
      onToggle={onToggle}
      isOpen={isOpen}
      setPhoto={setPhoto}
      onDeleteCertificate={onDeleteCertificate}
      setCertificate={setCertificate}
      // setDiplomaState={setDiplomaState}
      // setAddDiplomaCount={setAddDiplomaCount}
      // setCertificateState={setCertificateState}
      // onDeleteDiploma={onDeleteDiploma}
      // addCertificateCount={addCertificateCount}
      // setAddCertificateCount={setAddCertificateCount}
      formik={formik}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      handleSubmit={handleSubmit}
      getErrorMessage={getErrorMessage}
      setFieldValue={setFieldValue}
      setValue={setValue}
      // addDiplomaCount={addDiplomaCount}
      // isAddDiplomaDisabled={addDiplomaCount - diploma.length > 0}
      // isAddCertificateDisabled={addCertificateCount - certificate.length > 0}
    />
  );
};
